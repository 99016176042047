export const scrollToBeVisible = (ele: HTMLElement, container: HTMLElement) => {
  const eleTop = ele.offsetTop;
  const eleBottom = eleTop + ele.clientHeight;

  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  if (eleTop < containerTop) {
    // Scroll to the top of container
    container.scrollTop -= containerTop - eleTop;
    return;
  } else if (eleBottom > containerBottom) {
    // Scroll to the bottom of container
    container.scrollTop += eleBottom - containerBottom;
    return;
  }

  container.scrollTop = 0;
};

export const stringifyQuery = <T extends object>(data: T, prefix?: string): string =>
  Object.entries(data)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => {
      const serializedKey = prefix ? `${prefix}[${key}]` : key;

      if (typeof value === 'object') {
        return stringifyQuery(value, serializedKey);
      }

      return `${encodeURIComponent(serializedKey)}=${encodeURIComponent(value as string | number | boolean)}`;
    })
    .join('&');

export const extractQueryValue = (url: string, queryKey: string) => {
  // Decoding the URL
  const decodedUrl = decodeURIComponent(url);

  // Parsing the query parameters
  const urlParams = new URLSearchParams(decodedUrl);

  // Initialize a variable to store the first product_id value
  let queryValue: string | null = null;

  // Loop through all parameters to find the first product_id value
  urlParams.forEach((value, key) => {
    if (key.includes(queryKey) && queryValue === null) {
      queryValue = value;
    }
  });

  return queryValue;
};

export const invertObject = <T extends Record<string, string>>(obj: T): Record<string, string> =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));

export const clearTimeoutIfExists = (timerId?: NodeJS.Timeout) => {
  if (timerId !== undefined) {
    clearTimeout(timerId);
  }
};

export const getTopOffset = (element: HTMLElement | undefined | null): number | undefined => {
  if (!element) {
    return;
  }

  const rect = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  return rect.top + scrollTop;
};

export const toggleBodyScroll = (state: boolean) => {
  document.getElementsByTagName('body')[0].style.overflow = state ? 'hidden' : '';
};

export const calcProportionalHeight = (originalWidth: number, originalHeight: number, newWidth: number) => {
  const aspectRatio = originalHeight / originalWidth;

  return Math.round(newWidth * aspectRatio);
};
